.home-page {
  width: 100%;

  .home-page-row {
    display: flex;
    height: 100vh;
    width: 100%;

    @media all and (max-width: 900px) {
      flex-direction: column;
    }

    .side {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);

      @media all and (max-width: 900px) {
        width: 100%;
        height: 50vh;
      }

      img {
        width: 256px;
        height: auto;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);

        @media all and (max-width: 900px) {
          width: 128px;
        }
      }

      &:hover {
        width: 80%;

        @media all and (max-width: 900px) {
          width: 100%;
        }
      }

      &.side--left {
        &:hover {
          img {
            transform: scale(1.15);
          }
        }
      }

      &.side--right {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &:hover {
          img {
            transform: scale(1.15);
          }
        }
      }
    }
  }
}