.text-img-section {
  padding-top: 96px;
  padding-bottom: 96px;

  @media all and (max-width: 1200px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media all and (max-width: 900px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media all and (max-width: 600px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  p {
    font-size: 1.4rem;
  }

  .imgCol {
    display: flex;

    img {
      width: 100%;
      max-width: 500px;
      height: auto;
      margin: auto;

      // @media all and (max-width: 1200px) {
      //   max-width: 300px;
      // }

      @media all and (max-width: 900px) {
        margin-bottom: 8px;
      }
    }
  }

  .reversed {
    flex-direction: row-reverse;
  }

  .isMobileReversed {
    @media all and (max-width: 900px) {
      flex-direction: column-reverse;

      .imgCol {
        img {
          padding-top: 32px;
        }
      }
    }
  }

  &.smallImgOnMobile {
    .imgCol {

      img {
        @media all and (max-width: 900px) {
          max-width: 256px;
        }
      }
    }
  }
}