.hero-section-dev {
  // height: 70vh;
  width: 100%;
  padding-top: 128px;
  padding-bottom: 0px;

  @media all and (max-width: 900px) {
    padding-top: 64px;

    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  &.no-pt {
    padding-top: 32px;
  }

  .col {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .imgCol {
    @media all and (max-width: 900px) {
      min-height: 256px;
    }

    .bgBox {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

      @media all and (max-width: 1600px) {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      }

      @media all and (max-width: 900px) {
        box-shadow: none;
      }
    }
  }

  .contentCol {
    .contentBox {
      padding: 64px 32px !important;
      color: white;
      background: linear-gradient(270deg, rgba(241, 89, 43, 1) 30.11%, rgba(242, 146, 1, 1) 100%);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

      @media all and (max-width: 1600px) {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
        padding: 48px 24px !important;
      }

      @media all and (max-width: 900px) {
        padding: 32px 24px !important;
        box-shadow: none;
      }

      .infoBox {
        padding: 0px 32px;

        @media all and (max-width: 900px) {
          padding: 0px 0px;
        }

        .center {
          padding-bottom: 32px;

          p {
            letter-spacing: 0.2em;
          }
        }
      }

      h2 {
        font-weight: bold;
        font-size: 4rem;
        line-height: 1.2;
        font-weight: 500 !important;

        @media all and (max-width: 1600px) {
          font-size: 3.8rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 2.8rem;
        }
      }

      .btn {
        margin-top: 32px;
      }

      .break {
        height: 1px;
        background: white;
        width: 64px;
        margin-top: 32px;
        margin-bottom: 32px;

        @media all and (max-width: 1200px) {
          margin-top: 24px;
          margin-bottom: 24px;
        }

        @media all and (max-width: 900px) {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }

  &.lightGradient {
    .contentCol {
      .contentBox {
        background: linear-gradient(270deg, rgba(241, 89, 43, 0.7) 30.11%, rgba(242, 146, 1, 0.7) 100%);
      }
    }
  }

  .reversed {
    flex-direction: row-reverse;
  }
}